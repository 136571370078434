import { dehydrate, QueryClient } from '@tanstack/react-query'
import omitEmptyOrNil from '@vangst/lib/omitEmptyOrNil'
import { fetchPage } from '@vangst/services/oogst/page'
import {
  ElasticSearchEndpoints,
  prefetchElasticSearch,
} from '@vangst/services/oogst/search/useElasticSearch'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import Breadcrumbs from '../../components/navigation/Breadcrumbs'
import Meta from '../../domains/application/Meta'
import SearchVoltron from '../../domains/search/SearchVoltron'
import { parseParams } from '../../domains/search/useQueryParams'
import { getAddressFromVariables } from '../../domains/search/utils.server'

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const endpoint: ElasticSearchEndpoints = 'searchJobPostings'
  const queryClient = new QueryClient()
  const qp = context.resolvedUrl.replace('/jobs?', '')
  const variables = { ...parseParams(qp), first: 24 }
  await prefetchElasticSearch(queryClient, endpoint, variables)
  const page = await fetchPage(queryClient, { rawUrl: context.resolvedUrl })
  const address = await getAddressFromVariables(variables)

  const crumbs = [
    // { href: Routes.INDEX, text: 'Home' },
    { href: null, text: 'Jobs' },
  ]

  return {
    props: {
      address,
      crumbs,
      dehydratedState: omitEmptyOrNil(dehydrate(queryClient), true),
      endpoint,
      page: page?.getPage ? page.getPage : null,
      variables,
    },
  }
}

// -------------------------------------

type PropsType = InferGetServerSidePropsType<typeof getServerSideProps>

export default function JobsPage(props: PropsType) {
  const { crumbs, page } = props
  const { viewer } = useViewer()
  const endpoint = viewer?.permissions?.searchJobPostingsByRecruiter
    ? 'searchJobPostingsByRecruiters'
    : props.endpoint

  return (
    <>
      <Meta
        key="meta"
        canonicalUrl={page?.metaInfo.canonicalUrl}
        crumbs={crumbs}
        description={page?.metaInfo.description}
        publishDate={page?.postedAt}
        robots={page?.metaInfo.robots}
        title={page?.metaInfo.title ?? page?.title ?? 'Vangst | Jobs'}
      />
      <Breadcrumbs className="pointer-events-none opacity-0" crumbs={crumbs} />
      <SearchVoltron
        address={props.address}
        endpoint={endpoint}
        page={props.page}
        variables={props.variables}
      />
    </>
  )
}
